import { useMemo, useState } from "react";
import { RedLineContext } from "./RedLineContext";

export const RedLineProvider = ({ children }) => {
  const [redLine, setRedLine] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dateLoading, setDateLoading] = useState(null);

  const defaultProps = useMemo(
    () => ({
      redLine,
      setRedLine,
      loading,
      setLoading,
      dateLoading,
      setDateLoading,
    }),
    [redLine, loading, dateLoading]
  );

  return (
    <RedLineContext.Provider value={defaultProps}>
      {children}
    </RedLineContext.Provider>
  );
};
