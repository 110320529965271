import React, { useContext, useEffect, useState } from "react";
import {
  Route,
  Switch,
  Redirect,
  __RouterContext,
  useHistory,
} from "react-router";
import Loadable from "react-loadable";
import { withRouter } from "react-router";
import Header from "./components/Header/Header";
import Navigation from "./components/Navigation/Navigation";
import Footer from "./components/Footer/Footer";
import Modal from "./components/Modal/Modal";
import api from "./jsCommon/api";
import store from "./MobX";
import RedLine from "./components/RedLine/RedLine";
import { sendNotFoundPath } from "./funcs";
// import FinishOrder from "./pages/finish-order/FinishOrder";

const Load = () => {
  return <div></div>;
};

function useRouter() {
  return useContext(__RouterContext);
}

const Main = Loadable({
  loader: () => import("./pages/main/Main"),
  loading: Load,
});
const Catalog = Loadable({
  loader: () => import("./pages/catalog/Catalog"),
  loading: Load,
});
const ProductLoad = Loadable({
  loader: () => import("./pages/product/Product"),
  loading: Load,
});
const Cart = Loadable({
  loader: () => import("./pages/cart/Cart"),
  loading: Load,
});
const MakeOrder = Loadable({
  loader: () => import("./pages/make-order/MakeOrder"),
  loading: Load,
});
const ProfileAccount = Loadable({
  loader: () => import("./pages/personal-account/ProfileAccount"),
  loading: Load,
});
const ServicePage = Loadable({
  loader: () => import("./pages/service-page/ServicePage"),
  loading: Load,
});
const SearchNoResult = Loadable({
  loader: () => import("./pages/search-result/search-no-result/SearchNoResult"),
  loading: Load,
});

const FinishOrder = Loadable({
  loader: () => import("./pages/finish-order/FinishOrder"),
  loading: Load,
});

let lastPathCat = window.location.pathname;

const App = () => {
  const [noScroll, setNoScroll] = useState(false);
  const body = document.querySelector("body");
  const [showOverlay, setShowOverlay] = useState(false);
  const [mobileMenu, setMobileMenu] = useState(false);

  if (noScroll) {
    body.classList.add("no-scroll");
  } else {
    body.classList.remove("no-scroll");
  }
  const history = useHistory();
  const { location } = useRouter();

  useEffect(() => {
    api
      .getUser()
      .then((result) => {
        store.userData = result;
        if (store.userData.lastseen && store.userData.lastseen.length) {
          store.seeProds = store.userData.lastseen;
        }
      })
      .catch((err) => {
        console.error(err);
        if (process.env.REACT_APP_TYPE !== "local") {
          store.userData = null;
          if (location.pathname.includes("/profile")) history.replace("/login");
        }
      });

    api
      .getPins()
      .then((pins) => {
        if (pins?.length) {
          const pinsObj = {};
          pins.forEach((pin) => {
            pinsObj[pin.kod] = pin.znachenie;
          });
          store.pins = pinsObj;
        }
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
    if (!store.mainSEO.title)
      api
        .getMainSEO()
        .then((seo) => {
          store.mainSEO = seo;
        })
        .catch((err) => {
          console.error("err :>> ", err);
        });
  }, []);

  useEffect(() => {
    const catUrls = ["/catalog", "/brand", "/sale"];
    if (
      !location.pathname.includes("/product/") &&
      location.pathname !== lastPathCat
    )
      window.scroll({ top: 0, left: 0, behavior: "smooth" });
    if (catUrls.includes(location.pathname)) lastPathCat = location.pathname;
    if (window.ym) {
      window.ym(22994284, "hit", window.location.href);
    }
    if (window.fbq) {
      window.fbq("track", "PageView");
    }
    console.log("location :>> ", location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className={`${showOverlay ? "overlay" : ""}`}></div>
      <RedLine isMobile={store.isMobile} />
      <Header
        setNoScroll={setNoScroll}
        updateOverlay={setShowOverlay}
        noScroll={noScroll}
        mobileMenu={mobileMenu}
        setMobileMenu={setMobileMenu}
      />

      <Navigation
        setNoScroll={setNoScroll}
        noScroll={noScroll}
        mobileMenu={mobileMenu}
        updateOverlay={setShowOverlay}
        setMobileMenu={setMobileMenu}
      />
      <div className="content">
        <Switch>
          <Route path="/" exact render={() => <Main />} />
          <Route
            path="/gift/:promo"
            exact
            render={(routeProps) => (
              <Main promo={routeProps.match.params.promo} />
            )}
          />
          <Route
            path="/login"
            exact
            render={() => (
              (store.showModal = true),
              (store.isModalType = { auth: true }),
              (<Main />)
            )}
          />
          <Route
            path="/catalog/podarochnye-karty"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                razdel="podarochnye-karty"
                dontCategInTop={true}
              />
            )}
          />
          <Route
            path="/catalog/new"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                razdel="new"
                catalogFetch={true}
                dontCategInTop={true}
              />
            )}
          />
          {/* <Route
            path="/catalog/sale"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                razdel="sale"
                catalogFetch={true}
                dontCategInTop={true}
              />
            )}
          /> */}
          <Route
            path={[
              "/catalog",
              "/catalog/:razdel",
              "/catalog/:razdel/:subrazvel",
              "/catalog/:razdel/:subrazvel/:point",
            ]}
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                razdel={routeProps.match.params.razdel}
                subrazvel={routeProps.match.params.subrazvel}
                point={routeProps.match.params.point}
              />
            )}
          />
          <Route
            path="/landing/:slug"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                generateSlug={routeProps.match.params.slug}
              />
            )}
          />
          <Route
            path="/brand/:slug"
            exact
            render={(routeProps) => (
              <Catalog
                catalogFetch={true}
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                brandName={routeProps.match.params.slug}
              />
            )}
          />
          <Route
            path="/product/:slug"
            render={(routeProps) => (
              <ProductLoad
                updateOverlay={setShowOverlay}
                noScroll={setNoScroll}
                slug={routeProps.match.params.slug}
                routeProps={routeProps}
              />
            )}
          />
          <Route
            path={["/cart/:code", "/cart"]}
            render={(routeProps) => (
              <Cart code={routeProps.match.params.code} />
            )}
          />
          <Route path="/checkout" render={() => <MakeOrder />} />
          <Route
            path="/finish/:id"
            render={(routeProps) => (
              <FinishOrder id={routeProps.match.params.id} />
            )}
          />

          <Route
            path="/profile"
            render={(routeProps) => <ProfileAccount routeProps={routeProps} />}
          />

          <Route path="/service" render={() => <ServicePage />} />
          <Route
            path="/search"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                catalogFetch={true}
                search={true}
              />
            )}
          />

          <Route
            path="/sale"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                catalogFetch={true}
                sale={true}
                dontCategInTop={true}
              />
            )}
          />
          <Route
            path="/sale/:slug"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                catalogFetch={true}
                sale={true}
                saleSlug={routeProps.match.params.slug}
                // dontCategInTop={true}
              />
            )}
          />
          <Route
            path="/personal/:uid"
            exact
            render={(routeProps) => (
              <Catalog
                setNoScroll={setNoScroll}
                routeProps={routeProps}
                catalogFetch={true}
                personalPage={true}
                personalUID={routeProps.match.params.uid}
                dontCategInTop={true}
              />
            )}
          />
          <Route
            path="/search/not-found"
            exact
            render={(routeProps) => <SearchNoResult routeProps={routeProps} />}
          />

          <Route
            path="/404"
            exact
            render={(routeProps) => (
              <SearchNoResult isPageNotFound={true} routeProps={routeProps} />
            )}
          />
          <Route
            path="/google-market.xml"
            render={() => {
              const a = document.createElement("a");
              a.download = "google-market.xml";
              a.href = "/google-market.xml";
              a.click();
            }}
          />
          <Route
            path="/yandex-market.yml"
            render={() => {
              const a = document.createElement("a");
              a.download = "yandex-market.yml";
              a.href = "/yandex-market.yml";
              // console.log("a", a);
              a.click();
            }}
          />
          <Route
            path="/avito-feed.xml"
            render={() => {
              const a = document.createElement("a");
              // a.download = "yandex-market.yml";
              a.href = "/avito-feed.xml";
              // console.log("a", a);
              a.click();
            }}
          />

          <Route
            path="/sitemap.xml"
            render={() => {
              const a = document.createElement("a");
              a.download = "sitemap.xml";
              a.href = "/sitemap.xml";
              // console.log("a", a);
              a.click();
            }}
          />

          <Route
            path="*"
            render={() => {
              sendNotFoundPath(location.pathname);
              return <Redirect from="*" to="/" />;
            }}
          />
        </Switch>
      </div>
      <Modal />
      <Footer />
    </>
  );
};

export default withRouter(App);
