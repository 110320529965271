import React, { useEffect, useState } from "react";

import store from "../../../MobX";

const getOnlyChars = (str) => {
  if (str === undefined || str === null) return "";
  if (typeof str !== "string") str = String(str);
  str = str.toLowerCase();
  return str.replace(/\W+/g, " ");
};

const dataOrder = ["year", "brand", "model", "oem"];

const Compatibility = () => {
  const [searchString, setSearchString] = useState("");
  const [compatibilities, setCompatibilities] = useState([]);

  useEffect(() => {
    let compatibilities = store?.modalData?.compatibilities || [];

    if (searchString) {
      const searchStringLower = getOnlyChars(searchString);
      compatibilities = compatibilities.filter((comp) => {
        return Object.entries(comp).some(([key, value]) => {
          if (!dataOrder.includes(key)) return false;
          return getOnlyChars(value)?.includes(searchStringLower);
        });
      });
    }

    setCompatibilities(compatibilities);
  }, [store?.modalData?.compatibilities, searchString]);

  const linesHTML = compatibilities.map((comp) => (
    <tr>
      {dataOrder.map((key) => (
        <td>
          <span className="table__str-title">{comp[key]}</span>
        </td>
      ))}
    </tr>
  ));

  return (
    <>
      <div className="modal-window__size">
        <div>
          <input
            type="text"
            placeholder="Поиск..."
            className="mb10"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
          />
        </div>
        <table className="table">
          <thead className="thead">
            <tr>
              <th>Год</th>
              <th>Производитель</th>
              <th>Модель</th>
              <th>OEM</th>
            </tr>
          </thead>
          <tbody className="tbody">{linesHTML}</tbody>
        </table>
      </div>
    </>
  );
};

export default Compatibility;
