import { useContext, useEffect, useMemo, useRef } from "react";
import { RedLineContext } from "./RedLineContext";
import api from "../../jsCommon/api";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

/**
 * @typedef {Object} RedLine
 * @property {string} startDate
 * @property {string} stopDate
 * @property {string} workHours
 * @property {string} additText
 * @property {string} customText
 */

const TIME_TO_UPDATE = 1000 * 60 * 60; // 1 hour

/**
 *
 * @return {{redLine:RedLine, loading:boolean, isRedLineActive:boolean}}
 */
export const useRedLine = () => {
  const {
    redLine,
    setRedLine,
    loading,
    setLoading,
    dateLoading,
    setDateLoading,
  } = useContext(RedLineContext);

  const interval = useRef(null);

  useEffect(() => {
    const updateRedLine = () => {
      if (loading) return;

      if (dateLoading && new Date() - dateLoading < TIME_TO_UPDATE) {
        return;
      }

      setLoading(true);
      api
        .getRedLine()
        .then((data) => {
          setRedLine(data);
        })
        .catch(console.error)
        .finally(() => {
          setLoading(false);
          setDateLoading(new Date());
        });
    };

    if (!dateLoading) updateRedLine();
    interval.current = setInterval(updateRedLine, TIME_TO_UPDATE);

    return () => {
      clearInterval(interval.current);
    };
  }, [dateLoading]);

  const isRedLineActive = useMemo(() => {
    if (!redLine) return false;

    const { startDate, stopDate } = redLine;

    if (!startDate || !stopDate) return null;

    return dayjs().isBetween(startDate, stopDate);
  }, [redLine]);

  return {
    redLine,
    loading,
    isRedLineActive,
  };
};
