import React, { useEffect, useState } from "react";
import store from "../../MobX";
import { observer } from "mobx-react-lite";

const DropItem = observer(
  ({
    name,
    size,
    addSize,
    available,
    active,
    wait,
    pin,
    preorder,
    setChooseElem,
    data,
    setIsOpen,
    onChangeFunc,
  }) => {
    const { showModal, isModalType } = store;

    return (
      <>
        {size ? (
          <button
            className={
              available ? "drop__list-item " : "drop__list-item in-active"
            }
            onClick={(item) => {
              if (!available) {
                store.showModal = true;
                store.isModalType = { arrival: true, prod: data };
                setIsOpen(false);
              } else if (active) {
                setIsOpen(false);
              } else {
                // setChooseElem({ size, addSize });
                onChangeFunc(data);
                setIsOpen(false);
              }
            }}
          >
            <div className="drop__size">
              {size}
              <div className="drop__size-add">{addSize}</div>
              {pin && available ? (
                <div className="pin drop__pin">{pin}</div>
              ) : null}
            </div>

            {/* {!available ? (
              <a className="drop__size-link drop__size-link--avail">
                Узнать о наличии
              </a>
            ) : null} */}
          </button>
        ) : (
          <button
            className="drop__list-item"
            onClick={(item) => {
              onChangeFunc({ name });
              setIsOpen(false);
            }}
          >
            {name}
          </button>
        )}
      </>
    );
  }
);

const Drop = ({ options, def, onChangeFunc }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((isOpen) => !isOpen);

  useEffect(() => {
    store.isOpenDrop = isOpen;
    if (isOpen) {
      store.funcClickOnFakeOverlay = () => toggle();
    } else {
      store.funcClickOnFakeOverlay = () => {};
    }
  }, [isOpen]);

  const ifSize = "size" in def;
  const ifSmall = "isSmall" in def;

  const dropTitleHTML = ifSize ? (
    <button
      className="drop__btn"
      onClick={options?.length === 1 ? () => {} : toggle}
    >
      <div className="drop__size">
        {def.size}
        {def.addSize !== undefined && (
          <div className="drop__size-add">{def.addSize}</div>
        )}
        {def.pin !== null && def.pin !== undefined && (
          <div className="pin drop__pin drop__pin_main">
            <i className="i-house" />
            {def.pin}
          </div>
        )}
      </div>
      {!ifSmall ? <i class="i-down"></i> : ""}
    </button>
  ) : (
    <button className="drop__btn" onClick={toggle}>
      <span>{def.name}</span>
      <i class="i-down"></i>
    </button>
  );

  return (
    <>
      {ifSmall ? (
        <button className="p drop__btn--inline" onClick={toggle}>
          Размер: <span>{def.size}</span> <i className="i-down" />
        </button>
      ) : null}

      <div
        className={`drop ${isOpen ? "active" : ""} ${
          ifSmall ? "drop--small" : ""
        }`}
      >
        <div
          className={`drop__wrp ${
            options?.length === 1 ? "drop__wrp_one" : ""
          }`}
        >
          {!ifSmall ? dropTitleHTML : null}
          <div className="drop__list">
            {dropTitleHTML}
            {options.map((option) => (
              <DropItem
                name={option.name}
                isSmall={option.isSmall}
                size={option.size}
                addSize={option.addSize}
                available={option.available}
                wait={option.wait}
                data={option.data}
                pin={option.pin}
                active={def?.size === option?.size}
                preorder={option.preorder}
                // setChooseElem={setChooseElem}
                setIsOpen={setIsOpen}
                onChangeFunc={onChangeFunc}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Drop;
