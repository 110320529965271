import React from "react";
import store from "../../../MobX";
import { observer } from "mobx-react-lite";

const Message = observer(() => {
  const { modalData } = store;

  const { message, buttonText, isDanger } = modalData;

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    store.showModal = false;
    store.isModalType = {};
    store.modalData = {};
  };

  return (
    <form className="modal-window__form" onSubmit={onSubmit}>
      <p className="authorization-modal__activation-info authorization-modal__activation-info_message">
        {message || ""}
      </p>

      <button
        className={`btn btn_primary authorization-modal__form-btn ${
          isDanger ? "fail" : "success"
        }`}
        id="buttonAuth"
        type="submit"
      >
        {buttonText}
      </button>
    </form>
  );
});

export default Message;
