import React from "react";
import Callback from "./Callback/Callback";
import Auth from "./Auth/Auth";
import Arrival from "./Arrival/Arrival";
import Size from "./Size/Size";
import Cheap from "./Cheap/Cheap";
import store from "../../MobX";
import { observer } from "mobx-react-lite";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Advice from "./Advice/Advice";
import Compatibility from "./Compatibility/Compatibility";
import Message from "./Message/Message";

const ModalContent = ({ isModalType }) => {
  return (
    <>
      <SimpleBar style={{ maxHeight: "70vh" }} autoHide={false}>
        {isModalType.callback ? <Callback /> : null}
        {isModalType.auth ? <Auth /> : null}
        {isModalType.arrival ? <Arrival /> : null}
        {isModalType.size ? <Size /> : null}
        {isModalType.cheap ? <Cheap /> : null}
        {isModalType.advice ? <Advice /> : null}
        {isModalType.compatibility ? <Compatibility /> : null}
        {isModalType.message ? <Message /> : null}
      </SimpleBar>
    </>
  );
};

const Modal = observer(() => {
  const { showModal, isModalType, modalData } = store;

  let classString = "modal-window";

  if (isModalType.size || isModalType.advice || isModalType.compatibility)
    classString += " modal-window--size";
  if (isModalType.advice) classString += " modal-window_advice";

  return (
    <>
      {showModal ? (
        <div className="modal-wrp">
          <div className={classString}>
            <div className="modal-window__top">
              <h5 className="h5">
                {isModalType.callback ? <>Форма обратной связи</> : null}
                {isModalType.auth ? <>Авторизация</> : null}
                {isModalType.arrival ? <>Оповещение о поступлении</> : null}
                {isModalType.size ? <>Таблица размеров</> : null}
                {isModalType.cheap ? <>Нашли дешевле?</> : null}
                {isModalType.advice
                  ? store.modalData.name
                    ? store.modalData.name
                    : "Советы по подбору"
                  : null}
                {isModalType.compatibility ? "Совместимость товара" : null}
                {isModalType.message
                  ? modalData?.header || "Уведомление"
                  : null}
              </h5>
              <button
                className="modal-window__close"
                onClick={() => {
                  store.showModal = false;
                  store.isModalType = {};
                  // closeModal();
                }}
              >
                <i className="i-close" />
              </button>
            </div>
            <ModalContent isModalType={isModalType} />
          </div>
          <div
            className="overlay"
            onClick={() => {
              store.showModal = false;
              store.isModalType = {};
            }}
          ></div>
        </div>
      ) : null}
    </>
  );
});

export default Modal;
