import React from "react";
import noImage from "../../../../../img/noImage.png";
import { resizeImage } from "../../../../../jsCommon/common";

/**
 *
 * @param {{text:string, imageURI:string, setText:()=>void, setImageURI:()=>void}} param0
 * @returns
 */
export default function SertTextPic({ text, imageURI, setText, setImageURI }) {
  return (
    <>
      <div className="col col-8 col-s-12 sert-block__text">
        <div className="input-block">
          <label for="text">Введите текст поздравления</label>
          <textarea
            className=" "
            id="text"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Ваша почта"
          />
        </div>
      </div>
      <div className="col col-4 col-s-12 sert-block__photo">
        <div
          className={`sert-block__load-photo ${
            imageURI ? "" : "sert-block__load-photo_clear"
          }`}
        >
          <img
            src={imageURI ? imageURI : noImage}
            alt="Изображение не загружено"
          />
        </div>
        <button
          className={`sert-block__btn ${
            imageURI ? "sert-block__btn_delete" : ""
          } btn`}
          onClick={(e) => {
            if (imageURI) {
              setImageURI(null);
            } else e.currentTarget.querySelector("input").click();
          }}
        >
          {!imageURI ? "Добавить фото" : "Удалить фото"}
          <input
            className="sert-block__btn"
            type="file"
            name="loadFile"
            style={{ display: "none" }}
            accept="image/jpeg,image/png,image/svg+xml"
            onChange={(e) => {
              const file = e.target.files[0];
              const fileReader = new FileReader();

              // Инициируем функцию FileReader
              // fileReader.onload = function (file) {
              //   setImageURI(this.result);
              // };

              fileReader.onload = function (file) {
                // setImageURI(this.result);

                const size = new Blob([this.result]).size;
                const sizeMB = size / 1000000;

                if (sizeMB > 2) {
                  console.log("resize");
                  const image = new Image();
                  image.src = this.result;
                  image.onload = function () {
                    const sizeQuality = 2000000 / size;
                    const resizeImageURL = resizeImage(image, sizeQuality);

                    setImageURI(resizeImageURL);
                  };
                } else {
                  setImageURI(this.result);
                }
              };

              // Производим чтение картинки по URI
              fileReader.readAsDataURL(file);
              // fileReader.readAsArrayBuffer(file);
            }}
          ></input>
        </button>
      </div>
    </>
  );
}
