import React from "react";
import { useRedLine } from "../../providers/RedLine/useRedLine";
import dayjs from "dayjs";

const RedLine = ({ isMobile }) => {
  const { redLine, loading, isRedLineActive } = useRedLine();

  if (loading || !redLine) return null;

  const { startDate, stopDate, workHours, additText, customText } =
    redLine || {};

  if (!isRedLineActive) return null;

  let textToShow;

  const isMobileS = window.innerWidth <= 320;

  if (customText) {
    textToShow = customText;
  } else {
    const startDateFormat = dayjs(startDate).format("DD.MM");
    const stopDateFormat = dayjs(stopDate).format("DD.MM");
    textToShow = (
      <>
        Друзья, с {startDateFormat} по {stopDateFormat} мы работаем c{" "}
        {workHours}! {isMobile && !isMobileS ? <br /> : ""}
        {additText}
      </>
    );
  }

  return (
    <div className={`red-line `}>
      <p style={{ textTransform: "uppercase" }}>
        {textToShow}
        {/*Друзья, с 29.04 по 12.05 мы работаем с 11:00 до 18:00!*/}
        {/*{isMobile && !isMobileS ? <br /> : ""} С праздниками!*/}
        {/*{isMobile ? <br /> : ""} Все заказы будут обработаны 03.01!*/}
        {/*{isMobile ? <br /> : ""}{" "}*/}
        {/*Онлайн заказы обрабатываются в обычном режиме!*/}
        {/*{isMobile ? <br /> : ""} */}
        {/*{isMobile ? "" : "С ПРАЗДНИКОМ!"}*/}
        {/* {isMobile ? <br /> : ""} ВЕРНЁМСЯ К
        ВАМ 03.01!{!isMobile ? " С НОВЫМ ГОДОМ!" : ""} */}
      </p>
    </div>
  );
};

export default RedLine;
